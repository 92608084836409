var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Simple List Group" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeListGroupBasic) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _vm._v(
          " List groups are a flexible and powerful component to display a list of content. "
        )
      ]),
      _c(
        "b-list-group",
        [
          _c("b-list-group-item", [_vm._v("Cras justo odio")]),
          _c("b-list-group-item", [_vm._v("Dapibus ac facilisis in")]),
          _c("b-list-group-item", [_vm._v("Morbi leo risus")]),
          _c("b-list-group-item", [_vm._v("Porta ac consectetur ac")]),
          _c("b-list-group-item", [_vm._v("Vestibulum at eros")])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }