var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Anchors" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeListGroupAction) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [_vm._v("Turn a ")]),
        _c("code", [_vm._v("<b-list-group-item>")]),
        _c("span", [_vm._v(" into an actionable link ")]),
        _c("code", [_vm._v('(<a href="...">)')]),
        _c("span", [_vm._v(" by specifying either an ")]),
        _c("code", [_vm._v("href")]),
        _c("span", [_vm._v(" prop or ")]),
        _c("code", [_vm._v("router-link")]),
        _c("span", [_vm._v(" prop.")])
      ]),
      _c(
        "b-list-group",
        [
          _c(
            "b-list-group-item",
            { attrs: { active: "", href: "#some-link" } },
            [_vm._v(" Awesome link ")]
          ),
          _c("b-list-group-item", { attrs: { href: "#" } }, [
            _vm._v(" Link with active state ")
          ]),
          _c("b-list-group-item", { attrs: { href: "#" } }, [
            _vm._v(" Action links are easy ")
          ]),
          _c("b-list-group-item", { attrs: { href: "#" } }, [
            _vm._v(" Action links are fast ")
          ]),
          _c(
            "b-list-group-item",
            { attrs: { href: "#foobar", disabled: "" } },
            [_vm._v(" Disabled link ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }