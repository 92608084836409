var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Badges" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeListGroupBadge) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [_vm._v("Use Utility classes ")]),
        _c("code", [
          _vm._v(".d-flex .justify-content-between align-items-center")
        ]),
        _c("span", [_vm._v(" to create space between badge and your content.")])
      ]),
      _c(
        "b-list-group",
        [
          _c(
            "b-list-group-item",
            {
              staticClass: "d-flex justify-content-between align-items-center"
            },
            [
              _c("span", [
                _vm._v("Biscuit jelly beans macaroon danish pudding.")
              ]),
              _c(
                "b-badge",
                {
                  staticClass: "badge-round",
                  attrs: { variant: "primary", pill: "" }
                },
                [_vm._v(" 8 ")]
              )
            ],
            1
          ),
          _c(
            "b-list-group-item",
            {
              staticClass: "d-flex justify-content-between align-items-center"
            },
            [
              _c("span", [_vm._v("chocolate cheesecake candy")]),
              _c(
                "b-badge",
                {
                  staticClass: "badge-round",
                  attrs: { variant: "primary", pill: "" }
                },
                [_vm._v(" 7 ")]
              )
            ],
            1
          ),
          _c(
            "b-list-group-item",
            {
              staticClass: "d-flex justify-content-between align-items-center"
            },
            [
              _c("span", [_vm._v("Oat cake icing pastry pie carrot")]),
              _c(
                "b-badge",
                {
                  staticClass: "badge-round",
                  attrs: { variant: "primary", pill: "" }
                },
                [_vm._v(" 6 ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }